import { Flex, Spacer } from '@chakra-ui/react'
import {
  IoIosNavigate,
  IoIosPeople,
  IoMdAlert,
  IoMdExpand,
  IoMdEye,
  IoMdImages,
  IoMdLink,
  IoMdPin,
  IoMdPricetags,
  IoMdSearch,
  IoMdArrowRoundUp,
} from 'react-icons/io'
import {
  useGetBrandsQuery,
  useGetPlatformSubtypesByProductQuery,
  useGetTagsQuery,
  useGetAllUploadersQuery,
} from '../../generated/graphql'
import { useIsEmployeeView, useOrgID } from '../../hooks/id_token_claims'
import { containsFilterByType, genReportFilter } from '../../hooks/report_table_filters'
import {
  useSelectedReportType,
  useSelectedReportStatus,
  useSelectedReportFilters,
} from '../../pages/reports'
import {
  DarkWebNetwork,
  DarkWebSource,
  ReportFilterType,
  ReportType,
  Severity,
} from '../../utils/constants'
import {
  darkWebSortingMechanismToDisplay,
  getSpoofSortTypes,
  sortingMechanismToDisplay,
} from '../../utils/reports/report_sorting_mechanism'
import {
  PLATFORM_TYPE_DISPLAY_MAP,
  REPORT_TYPES_WITH_PLATFORMS,
} from '../../utils/reports/report_utils'
import SearchFilterToken from '../collection_reports_table/search_filter_token'
import SortDropdown from '../sort_dropdown'
import { ReportFilterDropdown, SHOW_ALL_FILTER_VALUE } from '../tables/filter_dropdown'
import { Classification } from '../../utils/constants'
import DateFilterDropdown from '../collection_reports_table/date_filter_dropdown/date_filter_dropdown'
import { IoCubeSharp } from 'react-icons/io5'
import { ReportSourceCategory, ReportSourceCategoryLabels } from '@/generated/enums'
import { formatSnakeStringWithCapitalization } from '../../utils/string_utils'
import { FEATURES_TO_RENDER } from '@/components/web2/detection_reasons/detection_reasons_table'
import {
  getPlatformSubtypeDisplayValue,
  PLATFORM_SUBTYPE_OTHER_DISPLAY_VALUE,
} from '@/utils/reports/platform_subtype_utils'
import { useMemo } from 'react'

const filterValueToDisplayWithUnverified = (value: string) => {
  const modifiedValue =
    value === Classification.UNKNOWN.toString() ? 'unverified' : value
  return formatSnakeStringWithCapitalization(modifiedValue)
}

const getBrandDisplayValue = (value, brandsData, isOrgUnifiedView) => {
  if (value === ReportFilterType.Brand) {
    return ReportFilterType.Brand
  } else if (value === SHOW_ALL_FILTER_VALUE) {
    return SHOW_ALL_FILTER_VALUE
  } else if (!brandsData) {
    return ''
  }
  const brand = brandsData.spoofing_entities.find((entity) => entity.id === value)
  return isOrgUnifiedView
    ? `${brand.entity_name} (${brand.organization.name})`
    : brand.entity_name
}

const getTagDisplayValue = (value, isOrgUnifiedView) => {
  if (value === ReportFilterType.Tag) {
    return isOrgUnifiedView ? 'Global Tag' : 'Tag'
  }
  return value
}

const getSourceCategoryDisplayValue = (category: ReportSourceCategory) => {
  const label =
    ReportSourceCategoryLabels[category] ??
    formatSnakeStringWithCapitalization(category)
  return label
}

const showPlatformSubtypeFilter = (product: ReportType) => {
  if (product === ReportType.DARK_WEB) return false // using subtabs instead
  return REPORT_TYPES_WITH_PLATFORMS.includes(product)
}

const generateFilterComponentsMap = ({
  style,
  brandOptions,
  spoofStatusFilterOptions,
  severityFilterOptions,
  sourceFilterOptions,
  uploaderFilterOptions,
  tagFilterOptions,
  tldFilterOptions,
  platformSubtypeFilterOptions,
  darkWebNetworkFilterOptions,
  darkWebSourceFilterOptions,
  detectionReasonFilterOptions,
  brandsData,
  platformSubtypesIdToDisplay,
  isOrgUnifiedView,
}) => ({
  [ReportFilterType.DateRange]: (
    <DateFilterDropdown
      marginRight="8px"
      mb={style == FilterBarStyle.VERTICAL ? '10px' : null}
      w="135px"
    />
  ),

  [ReportFilterType.Brand]: (
    <ReportFilterDropdown
      alphabetizeFilterOptions={true}
      disabled={!brandOptions?.length}
      dropdownIcon={<IoMdImages size="18" />}
      filterOptions={brandOptions}
      filterType={ReportFilterType.Brand}
      isMultiSelect={true}
      marginRight="8px"
      mb={style == FilterBarStyle.VERTICAL ? '10px' : null}
      showSearchBar={true}
      textMaxWidth="110px"
      valueToDisplayFunction={(value) =>
        getBrandDisplayValue(value, brandsData, isOrgUnifiedView)
      }
      w="135px"
    />
  ),

  [ReportFilterType.Classification]: (
    <ReportFilterDropdown
      dropdownIcon={<IoMdExpand size="18" />}
      filterOptions={spoofStatusFilterOptions}
      filterType={ReportFilterType.Classification}
      isMultiSelect={true}
      marginRight="8px"
      mb={style == FilterBarStyle.VERTICAL ? '10px' : null}
      textMaxWidth="110px"
      valueToDisplayFunction={filterValueToDisplayWithUnverified}
      w="135px"
    />
  ),

  [ReportFilterType.Severity]: (
    <ReportFilterDropdown
      dropdownIcon={<IoMdAlert size="18" />}
      filterOptions={severityFilterOptions}
      filterType={ReportFilterType.Severity}
      isMultiSelect={true}
      marginRight="8px"
      mb={style == FilterBarStyle.VERTICAL ? '10px' : null}
      textMaxWidth="110px"
      valueToDisplayFunction={formatSnakeStringWithCapitalization}
      w="135px"
    />
  ),

  [ReportFilterType.Source]: (
    <ReportFilterDropdown
      dropdownIcon={<IoMdPin size="18" />}
      filterOptions={sourceFilterOptions}
      filterType={ReportFilterType.Source}
      isMultiSelect={true}
      marginRight="8px"
      mb={style == FilterBarStyle.VERTICAL ? '10px' : null}
      showSearchBar={true}
      textMaxWidth="110px"
      valueToDisplayFunction={getSourceCategoryDisplayValue}
      w="135px"
    />
  ),

  [ReportFilterType.Uploader]: (
    <ReportFilterDropdown
      dropdownIcon={<IoMdArrowRoundUp size="18" />}
      filterOptions={uploaderFilterOptions}
      filterType={ReportFilterType.Uploader}
      isMultiSelect={true}
      marginRight="8px"
      mb={style == FilterBarStyle.VERTICAL ? '10px' : null}
      showSearchBar={true}
      textMaxWidth="110px"
      w="135px"
    />
  ),

  [ReportFilterType.Tag]: (
    <ReportFilterDropdown
      alphabetizeFilterOptions={true}
      dropdownIcon={<IoMdPricetags size="18" />}
      filterOptions={tagFilterOptions}
      filterType={ReportFilterType.Tag}
      isMultiSelect={true}
      marginRight="8px"
      mb={style == FilterBarStyle.VERTICAL ? '4px' : null}
      showSearchBar={true}
      textMaxWidth="110px"
      valueToDisplayFunction={(value) => getTagDisplayValue(value, isOrgUnifiedView)}
      w="135px"
    />
  ),

  [ReportFilterType.TLD]: (
    <ReportFilterDropdown
      dropdownIcon={<IoMdLink size="18" />}
      filterOptions={tldFilterOptions}
      filterType={ReportFilterType.TLD}
      isMultiSelect={true}
      marginRight="8px"
      mb={style == FilterBarStyle.VERTICAL ? '10px' : null}
      textMaxWidth="110px"
      valueToDisplayFunction={formatSnakeStringWithCapitalization}
      w="135px"
    />
  ),

  [ReportFilterType.PlatformSubtype]: (
    <ReportFilterDropdown
      dropdownIcon={<IoCubeSharp size="18" />}
      filterOptions={platformSubtypeFilterOptions}
      filterType={ReportFilterType.PlatformSubtype}
      isMultiSelect={true}
      marginRight="8px"
      mb={style == FilterBarStyle.VERTICAL ? '10px' : null}
      showSearchBar={true}
      textMaxWidth="110px"
      valueToDisplayFunction={(value) => platformSubtypesIdToDisplay[value] ?? value}
      w="135px"
    />
  ),

  [ReportFilterType.DarkWebNetwork]: (
    <ReportFilterDropdown
      dropdownIcon={<IoIosPeople size="18" />}
      filterOptions={darkWebNetworkFilterOptions}
      filterType={ReportFilterType.DarkWebNetwork}
      marginRight="8px"
      mb={style == FilterBarStyle.VERTICAL ? '4px' : null}
      textMaxWidth="110px"
      valueToDisplayFunction={formatSnakeStringWithCapitalization}
      w="135px"
    />
  ),

  [ReportFilterType.DarkWebSource]: (
    <ReportFilterDropdown
      dropdownIcon={<IoIosNavigate size="18" />}
      filterOptions={darkWebSourceFilterOptions}
      filterType={ReportFilterType.DarkWebSource}
      marginRight="8px"
      mb={style == FilterBarStyle.VERTICAL ? '4px' : null}
      textMaxWidth="110px"
      valueToDisplayFunction={formatSnakeStringWithCapitalization}
      w="135px"
    />
  ),

  [ReportFilterType.DetectionReason]: (
    <ReportFilterDropdown
      dropdownIcon={<IoMdEye size="18" />}
      filterOptions={detectionReasonFilterOptions}
      filterType={ReportFilterType.DetectionReason}
      isInternal={true}
      isMultiSelect={true}
      marginRight="8px"
      mb={style == FilterBarStyle.VERTICAL ? '10px' : null}
      textMaxWidth="110px"
      valueToDisplayFunction={formatSnakeStringWithCapitalization}
      w="135px"
    />
  ),
})

const getFiltersForReportType = (reportType: ReportType, isEmployeeView: boolean) => {
  if (reportType === ReportType.SUSPICIOUS_EMAILS) {
    return [ReportFilterType.DateRange, ReportFilterType.Source, ReportFilterType.Tag]
  }
  const visibleFilters: ReportFilterType[] = [
    ReportFilterType.DateRange,
    ReportFilterType.Brand,
    ReportFilterType.Classification,
    ReportFilterType.Severity,
    ReportFilterType.Source,
    ReportFilterType.Uploader,
    ReportFilterType.Tag,
  ] // all universal

  if (showPlatformSubtypeFilter(reportType)) {
    visibleFilters.push(ReportFilterType.PlatformSubtype)
  }

  if (reportType === ReportType.DOMAINS) {
    visibleFilters.push(ReportFilterType.TLD)
  }

  if (reportType === ReportType.DARK_WEB) {
    visibleFilters.push(ReportFilterType.DarkWebNetwork)
    visibleFilters.push(ReportFilterType.DarkWebSource)
  }

  if (isEmployeeView) {
    visibleFilters.push(ReportFilterType.DetectionReason)
  }

  return visibleFilters
}

export enum FilterBarStyle {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

type SpoofReportsTableFilterBarProps = {
  isOrgUnifiedView: boolean
  selectedOrgIds?: string[]
  style?: FilterBarStyle
}

// TODO: refactor higher products to utilize visible filters instead of hacky if statement style
const SpoofReportsTableFilterBar = ({
  isOrgUnifiedView,
  selectedOrgIds,
  style = FilterBarStyle.HORIZONTAL,
}: SpoofReportsTableFilterBarProps) => {
  const orgID = useOrgID()

  const [isEmployeeView] = useIsEmployeeView()
  const [selectedReportType] = useSelectedReportType()
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()
  const [selectedReportStatus] = useSelectedReportStatus()
  const { data: platformSubtypesData } = useGetPlatformSubtypesByProductQuery({
    variables: {
      product: PLATFORM_TYPE_DISPLAY_MAP[selectedReportType],
    },
  })
  const platformSubtypes = platformSubtypesData?.platform_subtypes || []

  const platformSubtypesIdToDisplay: { [key: string]: string } = Object.fromEntries(
    platformSubtypes.map((subtype) => [
      subtype.id,
      getPlatformSubtypeDisplayValue(subtype.name, selectedReportType),
    ]),
  )
  const visibleFilters = getFiltersForReportType(selectedReportType, isEmployeeView)

  const { data: brandsData } = useGetBrandsQuery({
    variables: {
      where: {
        organization_id: { _in: isOrgUnifiedView ? selectedOrgIds : [orgID] },
      },
    },
  })

  const { data: tagsData } = useGetTagsQuery({
    variables: { orgIDs: isOrgUnifiedView ? [] : [orgID], includeGlobal: true },
  })

  const { data: uploadersData, loading: uploadersLoading } = useGetAllUploadersQuery({
    variables: {
      organization_ids: isOrgUnifiedView ? [...selectedOrgIds] : [orgID],
    },
  })

  const uploadersInfo = useMemo(() => {
    return Array.from(
      new Set(
        uploadersData?.spoofing_reports
          ?.map((report) => report.uploader)
          .filter((uploader) => uploader),
      ),
    ).sort((a, b) => a.email.localeCompare(b.email))
  }, [uploadersData])

  const genReportFilters = (reportFilterType: ReportFilterType, values: string[]) => {
    return values.map((value) => genReportFilter(reportFilterType, value))
  }

  const tldValues = ['.com', '.xyz', '.us', '.io']

  const platformSubtypeFilterOptions = genReportFilters(
    ReportFilterType.PlatformSubtype,
    // entries sorted alphabetically, but with "other" at the end
    Object.entries(platformSubtypesIdToDisplay)
      .sort(([_a, a], [_b, b]) => {
        const aIsOther = a === PLATFORM_SUBTYPE_OTHER_DISPLAY_VALUE
        const bIsOther = b === PLATFORM_SUBTYPE_OTHER_DISPLAY_VALUE
        if (aIsOther !== bIsOther) {
          return aIsOther ? 1 : -1
        }
        return a.localeCompare(b)
      })
      .map(([id, _]) => id),
  )

  const darkWebNetworkFilterOptions = genReportFilters(
    ReportFilterType.DarkWebNetwork,
    Object.values(DarkWebNetwork),
  )

  const darkWebSourceFilterOptions = genReportFilters(
    ReportFilterType.DarkWebSource,
    Object.values(DarkWebSource),
  )

  const spoofStatusFilterOptions = genReportFilters(
    ReportFilterType.Classification,
    Object.values(Classification).filter((type) =>
      [
        Classification.ACTIVE.toString(),
        Classification.PARKED.toString(),
        Classification.DOWN.toString(),
      ].includes(type),
    ),
  )

  const severityFilterOptions = genReportFilters(
    ReportFilterType.Severity,
    Object.values(Severity),
  )

  const sourceFilterOptions = genReportFilters(
    ReportFilterType.Source,
    Object.values(ReportSourceCategory),
  )

  const uploaderFilterOptions =
    !uploadersLoading &&
    (isEmployeeView
      ? [
          ...uploadersInfo.map(
            (uploader) =>
              genReportFilter(ReportFilterType.Uploader, uploader?.email) || [],
          ),
        ]
      : [
          genReportFilter(ReportFilterType.Uploader, 'Doppel Analyst'),
          ...(uploadersInfo
            ?.filter(
              (uploader) =>
                !uploader.is_internal &&
                uploader.email != uploader.organization?.organization_email,
            )
            .map((uploader) =>
              genReportFilter(ReportFilterType.Uploader, uploader.email),
            ) || []),
        ])

  const tldFilterOptions = genReportFilters(ReportFilterType.TLD, tldValues)

  const brandOptions =
    brandsData?.spoofing_entities &&
    brandsData?.spoofing_entities.map((entity) => {
      return genReportFilter(ReportFilterType.Brand, entity.id)
    })

  const tagFilterOptions =
    tagsData?.tags &&
    tagsData?.tags
      .filter((tag) => isEmployeeView || !tag.is_internal)
      .map((tag) => genReportFilter(ReportFilterType.Tag, tag.name))

  const detectionReasonFilterOptions = genReportFilters(
    ReportFilterType.DetectionReason,
    FEATURES_TO_RENDER,
  )

  const filterComponentsMap = generateFilterComponentsMap({
    style,
    brandOptions,
    severityFilterOptions,
    spoofStatusFilterOptions,
    sourceFilterOptions,
    uploaderFilterOptions,
    tagFilterOptions,
    tldFilterOptions,
    platformSubtypeFilterOptions,
    darkWebNetworkFilterOptions,
    darkWebSourceFilterOptions,
    detectionReasonFilterOptions,
    brandsData,
    platformSubtypesIdToDisplay,
    isOrgUnifiedView,
  })

  return (
    <Flex
      align="left"
      flexDirection={style == FilterBarStyle.VERTICAL ? 'column' : 'row'}
    >
      {visibleFilters.map((filterType) => filterComponentsMap[filterType] || null)}

      {containsFilterByType(ReportFilterType.Search, selectedReportFilters) && (
        <Flex marginRight="8px">
          <SearchFilterToken
            dropdownIcon={<IoMdSearch size="16" />}
            filterType={ReportFilterType.Search}
            selectedReportFilters={selectedReportFilters}
            setSelectedReportFilters={setSelectedReportFilters}
          />
        </Flex>
      )}

      <Spacer />

      <SortDropdown
        sortToDisplay={
          selectedReportType == ReportType.DARK_WEB
            ? darkWebSortingMechanismToDisplay
            : sortingMechanismToDisplay
        }
        supportedSortTypes={getSpoofSortTypes(
          selectedReportType,
          selectedReportStatus,
          isEmployeeView,
        )}
      />
    </Flex>
  )
}

export default SpoofReportsTableFilterBar
