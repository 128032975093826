import { useEffect, useState } from 'react'
import { ReportFilterType } from '@/utils/constants'
import CustomFilterDropdown, {
  DropdownPosition,
} from '@/components/collection_reports_table/custom_filter_dropdown'
import { DateRangePicker, createStaticRanges } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import styles from './react_date_range_override.module.css'
import { Button, Flex } from '@chakra-ui/react'
import {
  ReportFilter,
  genReportFilter,
  upsertFilter,
} from '@/hooks/report_table_filters'
import { DOPPEL_CYBER_BLUE, DOPPEL_DARK_GREY, DOPPEL_WHITE } from '@/utils/style'
import { useSelectedContext } from '@/components/tables/filter_dropdown'
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns'
import moment from 'moment'
import { IoMdCalendar } from 'react-icons/io'

export enum DateFilterLabels {
  TODAY = 'Today',
  THIS_WEEK = 'This Week',
  THIS_MONTH = 'This Month',
  YEAR_TO_DATE = 'Year To Date',
  LAST_7_DAYS = 'Last 7 Days',
  LAST_30_DAYS = 'Last 30 Days',
  LAST_6_MONTHS = 'Last 6 Months',
}

type DatePickerRange = {
  startDate: Date
  endDate: Date
  key?: string
}

const ALL_STATIC_RANGES = createStaticRanges([
  {
    label: DateFilterLabels.TODAY,
    range: () => ({
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    label: DateFilterLabels.THIS_WEEK,
    range: () => ({
      startDate: startOfWeek(new Date()),
      endDate: endOfWeek(new Date()),
    }),
  },
  {
    label: DateFilterLabels.THIS_MONTH,
    range: () => ({
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    }),
  },
  {
    label: DateFilterLabels.YEAR_TO_DATE,
    range: () => ({
      startDate: startOfYear(new Date()),
      endDate: endOfYear(new Date()),
    }),
  },
  {
    label: DateFilterLabels.LAST_7_DAYS,
    range: () => ({
      startDate: addDays(startOfDay(new Date()), -7),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    label: DateFilterLabels.LAST_30_DAYS,
    range: () => ({
      startDate: addDays(startOfDay(new Date()), -30),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    label: DateFilterLabels.LAST_6_MONTHS,
    range: () => ({
      startDate: addMonths(startOfMonth(new Date()), -6),
      endDate: endOfMonth(new Date()),
    }),
  },
])

/**
 * Gets {startDate, endDate} for a label as shown on date range picker
 */
export const getDateRangeByLabel = (label: string) => {
  return ALL_STATIC_RANGES.find((range) => range.label === label)?.range()
}

export const makeDateFilterValue = (selectedRange: DatePickerRange): string => {
  const { startDate, endDate } = selectedRange
  const start = moment(startDate).format('YYYY-MM-DD')
  const end = moment(endDate).format('YYYY-MM-DD')
  return `${start}#${end}`
}

type DateFilterDropdownProps = {
  context?: [ReportFilter[], (filters: ReportFilter[]) => void]
  staticRangesToShow?: string[]
  filterType?: string
  dropdownPosition?: DropdownPosition
  disableClearSelection?: boolean // if true, even the x won't appear
  [key: string]: any
}

export default function DateFilterDropdown({
  context = null,
  staticRangesToShow = null,
  filterType = ReportFilterType.DateRange,
  dropdownPosition = DropdownPosition.TOP_LEFT_ALIGNED,
  disableClearSelection = false,
  ...props
}: DateFilterDropdownProps) {
  const [selectedFilters, setSelectedFilters] = useSelectedContext(context)
  const dateFilter = selectedFilters.find((filter) => filter.filterType === filterType)

  // TODO: move these off the ReportFilter type
  const filterToRange = (filter: ReportFilter, key?: string): DatePickerRange => {
    if (!filter) {
      return {
        startDate: new Date(),
        endDate: new Date(),
        key,
      }
    }
    const [startDate, endDate] = filter.value
      .split('#')
      .map((dateString) => moment(dateString).toDate())
    return { startDate, endDate, key }
  }

  const [selectedRange, setSelectedRange] = useState(
    filterToRange(dateFilter, 'selection'),
  )
  const [showDropdown, setShowDropdown] = useState(false)
  const handleSelect = (ranges) => {
    setSelectedRange(ranges.selection)
  }

  useEffect(() => {
    setSelectedRange(filterToRange(dateFilter, 'selection'))
  }, [dateFilter, showDropdown])

  /**
   * displays selected dropdown range with pretty hard space constraints
   * TODO to find a better design that can handle longer strings
   */
  const rangeToDisplay = (range: DatePickerRange): string => {
    const { startDate, endDate } = range
    const startYear = startDate.getFullYear()
    const endYear = endDate.getFullYear()
    const currentYear = new Date().getFullYear()
    const startMonth = startDate.getMonth() + 1
    const endMonth = endDate.getMonth() + 1
    const startDay = startDate.getDate()
    const endDay = endDate.getDate()

    if (startYear !== endYear) {
      return `${startYear} - ${endYear}`
    } else if (startDate.getFullYear() !== currentYear) {
      if (startMonth !== endMonth) {
        return `${startYear}/${startMonth}-${endMonth}`
      } else if (startDay !== endDay) {
        return `${startYear}/${startMonth}/${startDay}-${endDay}`
      } else {
        return `${startYear}/${startMonth}/${startDay}`
      }
    } else if (startMonth !== endMonth) {
      return `${startMonth}/${startDay} - ${endMonth}/${endDay}`
    } else if (startDay !== endDay) {
      return `${startMonth}/${startDay} - ${endMonth}/${endDay}`
    } else {
      return `${startMonth}/${startDay}`
    }
  }
  const onClick = () => {
    setSelectedFilters(
      upsertFilter(
        genReportFilter(
          filterType as ReportFilterType,
          makeDateFilterValue(selectedRange),
        ),
        selectedFilters,
      ),
    )
    setShowDropdown(false)
  }
  const staticRanges = ALL_STATIC_RANGES.filter(
    (range) => staticRangesToShow === null || staticRangesToShow.includes(range.label),
  )

  return (
    <CustomFilterDropdown
      disableClearSelection={disableClearSelection}
      dropdownPosition={dropdownPosition}
      filterToDisplay={(filter) => rangeToDisplay(filterToRange(filter))}
      filterType={filterType}
      icon={<IoMdCalendar size="18" />}
      selectedReportFilters={selectedFilters}
      setSelectedReportFilters={setSelectedFilters}
      setShowDropdown={setShowDropdown}
      showDropdown={showDropdown}
      {...props}
    >
      <Flex
        backgroundColor={DOPPEL_DARK_GREY}
        border="2px solid transparent"
        borderRadius="6px!important"
        boxShadow="md"
        direction="column"
        outlineOffset="2px"
        paddingTop="2"
      >
        <DateRangePicker
          className={styles.dateRangePicker}
          inputRanges={[]}
          maxDate={endOfYear(new Date())}
          minDate={new Date(2022, 0, 1)}
          onChange={handleSelect}
          ranges={[selectedRange]}
          staticRanges={staticRanges}
        />

        <Flex justifyContent="right">
          <Button
            bgColor={DOPPEL_CYBER_BLUE}
            color={DOPPEL_WHITE}
            margin="2"
            onClick={onClick}
            size="xs"
          >
            Apply
          </Button>
        </Flex>
      </Flex>
    </CustomFilterDropdown>
  )
}
