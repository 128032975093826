import { Button, Flex, IconButton } from '@chakra-ui/react'
import { IoMdClose } from 'react-icons/io'
import { ReportFilter } from '../../hooks/report_table_filters'
import { useClearReportSearch } from '../../pages/reports'
import {
  DOPPEL_BLACK,
  DOPPEL_CYBER_BLUE,
  DOPPEL_CLICKABLE_GREY,
  DOPPEL_SECURE,
  DOPPEL_WHITE,
} from '../../utils/style'

const SearchFilterToken = ({
  setSelectedReportFilters,
  selectedReportFilters,
  filterType,
  dropdownIcon,
}) => {
  const maybeFilterSelected = selectedReportFilters.filter(
    (filter: ReportFilter) => filter.filterType == filterType,
  )
  const isFilterSelected = maybeFilterSelected.length != 0

  const clearReportSearch = useClearReportSearch()

  return (
    <Button
      _hover={{ background: DOPPEL_SECURE }}
      bgColor={isFilterSelected ? DOPPEL_CYBER_BLUE : DOPPEL_CLICKABLE_GREY}
      color={isFilterSelected ? DOPPEL_WHITE : DOPPEL_BLACK}
      fontSize={12}
      h="30px"
      onClick={clearReportSearch}
    >
      <Flex h="18" w="100%">
        {dropdownIcon}

        <Flex
          alignItems="center"
          flex="1"
          h="100%"
          justifyContent="center"
          marginX="4px"
        >
          {isFilterSelected ? maybeFilterSelected[0].value : filterType}
        </Flex>

        {isFilterSelected && (
          <IconButton
            aria-label={''}
            backgroundColor="transparent"
            icon={<IoMdClose />}
            size="18"
          />
        )}
      </Flex>
    </Button>
  )
}

export default SearchFilterToken
