import { Box, Button, Flex, IconButton } from '@chakra-ui/react'
import { useEffect, useRef } from 'react'
import { ReportFilter, removeFilterByType } from '../../hooks/report_table_filters'
import {
  DOPPEL_CYBER_BLUE,
  DOPPEL_CYBER_BLUE_SHADE,
  DOPPEL_DARK_CLICKABLE,
  DOPPEL_DARK_CLICKABLE_HOVER,
  MENU_LIST_ZINDEX,
} from '../../utils/style'
import DoppelDefaultButton from '../shared/doppel_default_button'
import { CloseIcon } from '@chakra-ui/icons'

export enum DropdownPosition {
  TOP_LEFT_ALIGNED = 'top_left',
  TOP_RIGHT_ALIGNED = 'top_right',
}

const CustomFilterDropdown = ({
  setSelectedReportFilters,
  selectedReportFilters,
  filterType,
  children,
  dropdownPosition,
  showDropdown,
  setShowDropdown,
  filterToDisplay,
  icon,
  disableClearSelection,
  w = '180px',
  ...props
}) => {
  const dropdownRef = useRef(null)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false)
    }
  }

  const maybeFilterSelected = selectedReportFilters.filter(
    (filter: ReportFilter) => filter.filterType == filterType,
  )
  const isFilterSelected = maybeFilterSelected.length != 0

  const onClick = () => {
    setShowDropdown(!showDropdown)
  }

  const dropdownCoords = {
    [DropdownPosition.TOP_LEFT_ALIGNED]: { left: '6px', top: '35px' },
    [DropdownPosition.TOP_RIGHT_ALIGNED]: { right: '6px', top: '35px' },
  }

  return (
    <Flex position="relative" ref={dropdownRef} w={w}>
      <DoppelDefaultButton
        _active={{
          backgroundColor: isFilterSelected
            ? DOPPEL_CYBER_BLUE_SHADE
            : DOPPEL_DARK_CLICKABLE_HOVER,
        }}
        _hover={{
          backgroundColor: isFilterSelected
            ? DOPPEL_CYBER_BLUE_SHADE
            : DOPPEL_DARK_CLICKABLE_HOVER,
        }}
        as={Button}
        bgColor={isFilterSelected ? DOPPEL_CYBER_BLUE : DOPPEL_DARK_CLICKABLE}
        color={!isFilterSelected ? 'white' : 'white'}
        fontSize={12}
        h="30px"
        isActive={false}
        leftIcon={icon}
        onClick={onClick}
        rightIcon={
          !disableClearSelection &&
          isFilterSelected && (
            <IconButton
              aria-label="Clear selection"
              as={Box}
              h="16px"
              icon={<CloseIcon h="8px" w="8px" />}
              isRound={true}
              onClickCapture={() =>
                setSelectedReportFilters(
                  removeFilterByType(filterType, selectedReportFilters),
                )
              }
              size="16px"
              w="16px"
            />
          )
        }
        w={w}
        {...props}
      >
        <Flex height="18" w="100%">
          <Flex alignItems="center" flex="1" h="100%" justifyContent="center">
            {!isFilterSelected ? filterType : filterToDisplay(maybeFilterSelected[0])}
          </Flex>
        </Flex>
      </DoppelDefaultButton>

      {showDropdown && (
        <Box
          overflow="visible"
          position="absolute"
          zIndex={MENU_LIST_ZINDEX}
          {...dropdownCoords[dropdownPosition]}
        >
          {children}
        </Box>
      )}
    </Flex>
  )
}

export default CustomFilterDropdown
