import { Text } from '@chakra-ui/react'
import moment from 'moment-timezone'

const TableTimestamp = ({ date }) => {
  if (!date) return <></>
  return (
    <>
      <Text>{moment.utc(date).tz(moment.tz.guess()).format('MM/DD/YY')}</Text>

      <Text>{moment.utc(date).tz(moment.tz.guess()).format('hh:mm a')}</Text>
    </>
  )
}

export default TableTimestamp
